const el = require('../../../lib/utils/jsx_component');
const localNewsLatest = require('./local_news_latest_articles');
const localNewsTopStories = require('./local_news_top_stories_articles');
const localNewsStream = require('./local_news_stream_articles');

// content blocks 1(Latest News): 0-3
// content block 2 (Top Stories) : 4-10
// content block 3 (stream):
// we are not getting stories for local news min: 0, max: 11
// recommendation would be remove block 2 and go with block 1 and block 3
module.exports = (articles = [], readMoreUrl = '') => {
  const copyArticles = articles.map(v => v);
  const contentBlockLatestNews = copyArticles.splice(0, 4);
  const contentBlockTopStories = copyArticles.splice(0, 7);
  const contentBlockStream = copyArticles.splice(0, copyArticles.length);
  return el("div", {
    className: "m-ln m-ln__wrapper"
  }, localNewsLatest(contentBlockLatestNews, readMoreUrl), localNewsTopStories(contentBlockTopStories, readMoreUrl), localNewsStream(contentBlockStream, readMoreUrl));
};