const el = require('../../../lib/utils/jsx_component');
const noDataLayout = require('./no_news_layout');
const {
  getLinkTarget
} = require('../../../lib/utils/string');
const leftHtml = (item, index) => {
  const {
    id,
    link,
    title,
    thumbnail,
    provider
  } = item || {};
  return el("div", null, el("a", {
    className: "m-ln__link",
    href: link,
    target: getLinkTarget(link, false),
    "data-ylk": `elm:hdln;g:${id};cpos:${index}`
  }, el("div", null, thumbnail ? el("div", {
    className: "m-ln__image"
  }, el("img", {
    src: thumbnail,
    alt: title
  })) : null, el("div", {
    className: "article-item"
  }, el("span", {
    className: "m-ln__provider"
  }, provider?.displayName || ''), el("h2", {
    className: "m-ln__heading"
  }, title)))));
};
const rightHtml = (item, index) => {
  const {
    id,
    link,
    title,
    thumbnail,
    provider
  } = item || {};
  const originalUrlRightClass = thumbnail ? 'cell xxs-8' : 'cell xxs-12';
  return el("div", null, el("a", {
    className: "m-ln__link",
    href: link,
    target: getLinkTarget(link, false),
    "data-ylk": `elm:hdln;g:${id};cpos:${index}`
  }, el("div", {
    className: "grid-x grid-margin-x"
  }, el("div", {
    className: originalUrlRightClass
  }, el("div", {
    className: "article-item"
  }, el("span", {
    className: "m-ln__provider"
  }, provider?.displayName || ''), el("h2", {
    className: "m-ln__heading"
  }, title))), thumbnail ? el("div", {
    className: "cell xxs-4"
  }, el("div", {
    className: "m-ln__image"
  }, el("img", {
    src: thumbnail,
    alt: title
  }))) : null)));
};
module.exports = (articles = [], readMoreUrl = '') => {
  const article_1 = articles.splice(0, 1);
  const article_2 = articles.splice(0, 3);
  const localNewsLatest = article_1.length > 0 ? el("div", {
    className: "m-ln__body grid-x grid-margin-x"
  }, el("div", {
    className: "m-ln__content m-ln__content--left cell xxs-12 xs-4 s-4 l-4"
  }, article_1.map(leftHtml)), article_2.length > 0 ? el("div", {
    className: "m-ln__content m-ln__content--center cell xxs-12 xs-3 s-3 l-3"
  }, article_2.map(rightHtml)) : null, articles.length > 0 ? el("div", {
    className: "m-ln__content m-ln__content--right cell xxs-12 xs-3 s-3 l-3"
  }, articles.map(rightHtml)) : null) : noDataLayout();
  const localNewsLatestHeader = el("h2", {
    className: "m-ln__title"
  }, " Top Stories ");
  return el("div", {
    className: "m-ln__topstories m-ln--7",
    "data-rapid": "true",
    "data-ylk": "subsec:top-stories;ll2:local-news;mpos:2;"
  }, el("div", null, localNewsLatestHeader), el("div", null, localNewsLatest));
};