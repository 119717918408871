import { debounce } from '../utils/timing'

const DEFAULT_OPTIONS = {
  carouselClass: 'm-cb__carousel'
}
export default (() => {
  class Carousel {
    constructor(elem, instance, options = {}) {
      if (!elem) return
      this.options = { ...DEFAULT_OPTIONS, ...options }
      this.elem = elem
      this.instance = instance
      this.nextButton = this.elem.querySelector(`.${this.options.carouselClass}__btn--next`)
      this.prevButton = this.elem.querySelector(`.${this.options.carouselClass}__btn--prev`)
      this.slides = [...this.elem.querySelectorAll(`.${this.options.carouselClass}--slide`)]
      this.subCarousel = this.elem.querySelector(`.${this.options.carouselClass}`)
      if (!this.slides.length || (!this.nextButton && !this.prevButton)) return

      this.windowResizeHanlder = debounce(this._handleWindowResize.bind(this), 300)

      this._addEventListeners()
    }

    scrollToElement(elem, dir = 'center') {
      elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: dir })
    }

    onDestroy() {
      window.removeEventListener('resize', this.windowResizeHanlder)
    }

    _addEventListeners() {
      this.nextButton?.addEventListener('click', this._handleNextClick.bind(this))
      this.prevButton?.addEventListener('click', this._handlePrevClick.bind(this))
      this.elem?.addEventListener('scroll', debounce(this._handleScroll.bind(this), 300))
      this.subCarousel?.addEventListener('scroll', debounce(this._handleScroll.bind(this), 300))
      window?.addEventListener('resize', this.windowResizeHanlder)
      this._handleWindowResize()
    }

    _handleWindowResize() {
      const hasNextSlide = this.slides.find(this._isOffScreenRight)
      const hasPrevSlide = this.slides.findLast(this._isOffScreenLeft)

      if (!hasNextSlide) {
        this.nextButton.setAttribute('disabled', true)
      } else {
        this.nextButton.removeAttribute('disabled')
      }
      if (!hasPrevSlide) {
        this.prevButton.setAttribute('disabled', true)
      } else {
        this.prevButton.removeAttribute('disabled')
      }
    }

    // This function checks to see if an element is hidden with css and not just offscreen
    _elementActivelyHidden(elem) {
      const styles = window.getComputedStyle(elem)
      return (
        styles.visibility === 'hidden' ||
        styles.display === 'none' ||
        !(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
      )
    }

    _handleScroll(e) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth
      // This find condition is here has xs has and extra slide that is not a part of the carousel on xxs
      const firstSlide = this.slides.find(
        slide =>
          !(clientWidth < 601 && slide.classList.contains('js-ignore-xs')) &&
          !this._elementActivelyHidden(slide)
      )
      const lastSlide = this.slides.findLast(slide => !this._elementActivelyHidden(slide))

      if (this._isOffScreenLeft(firstSlide)) {
        this.prevButton.removeAttribute('disabled')
      } else {
        this.prevButton.setAttribute('disabled', true)
      }

      if (this._isOffScreenRight(lastSlide)) {
        this.nextButton.removeAttribute('disabled')
      } else {
        this.nextButton.setAttribute('disabled', true)
      }
    }

    _handleNextClick(e) {
      // Get first item to the right of the viewport
      const nextSlide = this.slides.find(this._isOffScreenRight)

      if (nextSlide) {
        this.scrollToElement(nextSlide)
      }
    }

    _handlePrevClick(e) {
      // Get first item to the left of the viewport
      const prevSlide = this.slides.findLast(this._isOffScreenLeft)
      if (prevSlide) {
        this.scrollToElement(prevSlide)
      }
    }

    _isOffScreenLeft(elem) {
      const bounding = elem.getBoundingClientRect()
      return bounding.left < -10
    }

    _isOffScreenRight(elem) {
      const bounding = elem.getBoundingClientRect()
      return bounding.right > (window.innerWidth || document.documentElement.clientWidth)
    }
  }

  return Carousel
})()
